<template>
    <div class="min-h-screen bg-white text-black transition-colors duration-300 dark:bg-gray-800 dark:text-white">
        <!-- <ErrorPage v-if="error" :error="error" /> -->
        <div class="flex min-h-screen flex-col">
            <header class="dark:bg-slate-700">
                <div class="mx-auto w-full max-w-screen-xl p-4 xl:p-0">
                    <NavMenu />
                </div>
            </header>
            <main class="flex-grow overflow-x-hidden bg-slate-50 pb-24 dark:bg-slate-700">
                <div class="mx-auto w-full max-w-screen-xl p-4 xl:p-0">
                    <slot />
                </div>
            </main>
            <footer class="bg-blue-demegro text-white dark:bg-dark-blue-demegro">
                <div class="mx-auto w-full max-w-screen-xl p-4 xl:p-0">
                    <NavFooter />
                </div>
            </footer>
        </div>
    </div>
</template>

<style scoped>
.flex-grow {
    flex-grow: 1;
    overflow-y: auto;
}
</style>
