import type { SetColors } from '~/constants/colors'
import { colors } from './colors'

export const ERROR_KEYS = {
    ERROR: 'error',
    NO_STOCK: 'no_stock_for_expiration',
    ADD_FAILED: 'add_failed',
    UPDATE_FAILED: 'update_failed',
    REMOVE_FAILED: 'remove_failed',
    INVALID_AMOUNT: 'invalid_amount',
    LOAD_FAILED: 'load_failed',
    INVALID_CREDENTIALS: 'invalid_credentials',
    SESSION_EXPIRED: 'session_expired',
    LOGIN_REQUIRED: 'login_required',
    SCANGO_LOAD_FAILED: 'scango_load_failed',
    MINMAX_INVALID: 'minmax_invalid',
    MISSING_PARAMETERS: 'missing_parameters',
    COMPLETE_FAILED: 'complete_failed',
    MISSING_CONTACT_PERSON: 'missing_contact_person',
    MISSING_DELIVERY_DATE: 'missing_delivery_date',
    MISSING_ITEMS: 'missing_items',
    DISCOUNT_APPLY_FAILED: 'discount_apply_failed',
    DISCOUNT_REMOVE_FAILED: 'discount_remove_failed',
    TEMPLATE_NOT_FOUND: 'template_not_found',
    EMAIL_FAILED: 'email_failed',
    PASSWORD_RESET_FAILED: 'password_reset_failed',
    EMAIL_UNKNOWN: 'email_unknown',
    EMAIL_REQUIRED: 'email_required',
    PASSWORDS_DONT_MATCH: 'passwords_dont_match',
    INVALID_PASSWORD: 'invalid_password',
    PASSWORD_CHANGE_FAILED: 'password_change_failed',
    UNSAFE_PASSWORD: 'unsafe_password',
} as const

export type ErrorKey = (typeof ERROR_KEYS)[keyof typeof ERROR_KEYS]

export type Locale = 'nl' | 'en'

export interface ErrorMessage {
    message: string
    colors: SetColors
}

export type ErrorMessagesMap = {
    [K in ErrorKey]: Record<Locale, ErrorMessage>
}

export const errorMessages: ErrorMessagesMap = {
    // General
    error: {
        nl: { message: 'Er is iets fout gegaan', colors: colors.danger },
        en: { message: 'An error occurred', colors: colors.danger },
    },
    missing_parameters: {
        nl: { message: 'Er ontbreken parameters', colors: colors.danger },
        en: { message: 'Missing parameters', colors: colors.danger },
    },

    // Cart
    no_stock_for_expiration: {
        nl: { message: 'Geen voorraad beschikbaar voor deze vervaldatum', colors: colors.warning },
        en: { message: 'No stock available for this expiration date', colors: colors.warning },
    },
    add_failed: {
        nl: { message: 'Er is iets fout gegaan bij het toevoegen aan uw winkelwagen', colors: colors.danger },
        en: { message: 'Failed to add item to cart', colors: colors.danger },
    },
    update_failed: {
        nl: { message: 'Er is iets fout gegaan bij het bijwerken van het aantal', colors: colors.danger },
        en: { message: 'Failed to update item quantity', colors: colors.danger },
    },
    remove_failed: {
        nl: { message: 'Er is iets fout gegaan bij het verwijderen van het artikel', colors: colors.danger },
        en: { message: 'Failed to remove item from cart', colors: colors.danger },
    },
    invalid_amount: {
        nl: { message: 'Je moet minimaal 1 aantal toevoegen aan je winkelwagen', colors: colors.warning },
        en: { message: 'You must add at least 1 item to your cart', colors: colors.warning },
    },
    load_failed: {
        nl: { message: 'Er is iets fout gegaan bij het laden van je winkelwagen', colors: colors.warning },
        en: { message: 'Failed to load your cart', colors: colors.warning },
    },
    complete_failed: {
        nl: { message: 'Er is iets fout gegaan bij het afronden van je bestelling', colors: colors.danger },
        en: { message: 'Failed to complete order', colors: colors.danger },
    },
    missing_contact_person: {
        nl: { message: 'Vul een naam in', colors: colors.danger },
        en: { message: 'Fill in a name', colors: colors.danger },
    },
    missing_delivery_date: {
        nl: { message: 'Selecteer een datum', colors: colors.danger },
        en: { message: 'Select a date', colors: colors.danger },
    },
    missing_items: {
        nl: { message: 'Je winkelwagen is leeg', colors: colors.danger },
        en: { message: 'Your cart is empty', colors: colors.danger },
    },
    discount_apply_failed: {
        nl: { message: 'Er is iets fout gegaan bij het toepassen van de kortingscode', colors: colors.danger },
        en: { message: 'An error occurred while applying the discount code', colors: colors.danger },
    },
    discount_remove_failed: {
        nl: { message: 'Er is iets fout gegaan bij het verwijderen van de kortingscode', colors: colors.danger },
        en: { message: 'An error occurred while removing the discount code', colors: colors.danger },
    },

    // Auth
    invalid_credentials: {
        nl: { message: 'Ongeldige inloggegevens', colors: colors.danger },
        en: { message: 'Invalid credentials', colors: colors.danger },
    },
    session_expired: {
        nl: { message: 'Uw sessie is verlopen, log opnieuw in', colors: colors.warning },
        en: { message: 'Your session has expired, please log in again', colors: colors.warning },
    },
    login_required: {
        nl: { message: 'Je moet eerst inloggen om je winkelwagen te beheren', colors: colors.info },
        en: { message: 'You must be logged in to manage your cart', colors: colors.info },
    },
    template_not_found: {
        nl: { message: 'Er is iets fout gegaan bij het versturen van de e-mail', colors: colors.danger },
        en: { message: 'An error occurred while sending the email', colors: colors.danger },
    },
    email_failed: {
        nl: { message: 'Er is iets fout gegaan bij het versturen van de e-mail', colors: colors.danger },
        en: { message: 'An error occurred while sending the email', colors: colors.danger },
    },
    password_reset_failed: {
        nl: { message: 'Er is iets fout gegaan bij het resetten van je wachtwoord', colors: colors.danger },
        en: { message: 'An error occurred while resetting your password', colors: colors.danger },
    },
    email_unknown: {
        nl: { message: 'Dit e-mailadres is niet bekend', colors: colors.danger },
        en: { message: 'This email address is not known', colors: colors.danger },
    },
    email_required: {
        nl: { message: 'Vul een e-mailadres in', colors: colors.danger },
        en: { message: 'Fill in an email address', colors: colors.danger },
    },
    passwords_dont_match: {
        nl: { message: 'De nieuwe wachtwoorden komen niet overeen', colors: colors.danger },
        en: { message: 'The new passwords do not match', colors: colors.danger },
    },
    invalid_password: {
        nl: { message: 'Uw oude wachtwoord is niet correct', colors: colors.danger },
        en: { message: 'Your old password is not correct', colors: colors.danger },
    },
    password_change_failed: {
        nl: { message: 'Er is iets fout gegaan bij het wijzigen van uw wachtwoord', colors: colors.danger },
        en: { message: 'An error occurred while changing your password', colors: colors.danger },
    },
    unsafe_password: {
        nl: { message: 'Uw wachtwoord is niet veilig. Het moet minimaal 8 tekens lang zijn, een kleine letter, een hoofdletter en een cijfer bevatten.', colors: colors.danger },
        en: { message: 'Your password is not safe. It must be at least 8 characters long, contain a lowercase letter, an uppercase letter and a number.', colors: colors.danger },
    },

    // Scan & Go
    scango_load_failed: {
        nl: { message: 'Er is een fout opgetreden bij het laden van Scan & Go', colors: colors.danger },
        en: { message: 'An error occurred while loading Scan & Go', colors: colors.danger },
    },
    minmax_invalid: {
        nl: { message: 'Minimum waarde kan niet groter zijn dan maximum waarde', colors: colors.warning },
        en: { message: 'Minimum value cannot be greater than maximum value', colors: colors.warning },
    },
}
