import type { Ref } from 'vue'
// composables/useAuthBroadcast.ts
import { useBroadcastChannel } from '@vueuse/core'

type AuthEvent = 'login' | 'logout'

interface AuthBroadcastReturn {
    broadcastLogin: () => void
    broadcastLogout: () => void
    isSupported: Ref<boolean>
    error: Ref<Event | null>
    cleanup: () => void
}

export default function useAuthBroadcast(): AuthBroadcastReturn {
    if (!import.meta.client) {
        const falsyRef = ref(false)
        const nullRef = ref(null)
        return {
            broadcastLogin: () => {},
            broadcastLogout: () => {},
            isSupported: falsyRef,
            error: nullRef,
            cleanup: () => {},
        }
    }

    const authStore = useAuthStore()
    const { isSupported, post, data, error, close } = useBroadcastChannel<AuthEvent, AuthEvent>({
        name: 'demegro-auth-channel',
    })

    const handleAuthEvent = async (event: AuthEvent) => {
        try {
            switch (event) {
                case 'login':
                    if (!authStore.authenticated) {
                        await authStore.handleRefresh()
                        await authStore.clearAndRefreshNuxt()
                    }
                    break
                case 'logout':
                    if (authStore.authenticated) {
                        await authStore.handleLogout()
                        await authStore.clearAndRefreshNuxt()
                    }
                    break
            }
        } catch (err) {
            console.error('[AuthBroadcast] Error:', err)
        }
    }

    const stopWatch = watch(data, handleAuthEvent)

    onUnmounted(() => {
        stopWatch()
        close()
    })

    return {
        broadcastLogin: () => isSupported.value && post('login'),
        broadcastLogout: () => isSupported.value && post('logout'),
        isSupported,
        error,
        cleanup: close,
    }
}
