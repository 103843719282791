<template>
    <div>
        <div
            class="group relative text-lg font-bold"
            @mouseenter="mouseOver"
            @mouseleave="mouseLeave"
            @click="handleClick"
        >
            <!-- No subitems -->
            <NuxtLink
                v-if="!item.subItems"
                :to="item.path"
                class="btn w-full text-nowrap font-bold"
                :class="{
                    'text-blue-demegro': isActive && !depth,
                    'text-[16px] text-blue-demegro': isActive && depth,
                    'py-2 text-[16px] text-black-demegro': depth,
                }"
            >
                <span>
                    <font-awesome-icon
                        v-if="item.icon"
                        :icon="item.icon"
                        class="mr-1 mt-2"
                        :class="{
                            'text-blue-demegro': !isActive && !isFavoritesItem,
                            'text-inherit': isFavoritesItem,
                            'text-yellow-400': isActive && isFavoritesItem,
                        }"
                    />
                    {{ item.label }}
                </span>
            </NuxtLink>

            <!-- With subitems -->
            <NuxtLink
                v-else
                :to="item.path"
                class="btn w-full text-nowrap font-bold"
                :class="{
                    'text-blue-demegro': isActive && !depth,
                    'text-[16px] text-blue-demegro': isActive && depth,
                    'py-2 text-[16px] text-black-demegro': depth,
                }"
            >
                <span>{{ item.label }}</span>
                <font-awesome-icon
                    v-if="!depth"
                    :icon="['fas', 'chevron-down']"
                    :class="{ 'rotate-180 transform': isOpen }"
                    class="stroke-3 ml-3 mt-2 text-xs"
                />
            </NuxtLink>

            <!-- Dropdown content -->
            <Transition>
                <div
                    v-if="item.subItems && isOpen"
                    class="absolute z-20 rounded-xl bg-white px-2.5 shadow-2xl ring-1 ring-black ring-opacity-5 dark:bg-gray-800"
                    :class="{
                        'left-0 top-full mt-[54px]': depth === 0,
                        'left-full top-0': depth !== 0,
                    }"
                    @mouseenter="mouseOver"
                    @mouseleave="mouseLeave"
                >
                    <template v-if="loading">
                        <NavMenubarSubitemSkeleton />
                    </template>
                    <template v-else>
                        <template v-for="(subItem, index) in item.subItems" :key="index">
                            <NavMenubarSubitem :item="subItem" :depth="depth + 1" />
                            <div
                                v-if="index < item.subItems.length - 1"
                                class="h-px bg-gray-demegro-light dark:bg-dark-gray-demegro"
                            />
                        </template>
                    </template>
                </div>
            </Transition>
        </div>
    </div>
</template>

<script setup lang="ts">
import type { MenuItem } from '~/types/application'

interface Props {
    item: MenuItem
    depth?: number
    loading?: boolean
}

const props = withDefaults(defineProps<Props>(), {
    depth: 0,
    loading: false,
})

const emit = defineEmits<{
    handleClick: []
}>()

const route = useRoute()
const isActive = computed(() => {
    return route.path.startsWith(props.item.path) && (props.item.path !== '/' || route.path === '/')
})
const isFavoritesItem = computed(() => {
    return props.item.path === '/assortiment/voorkeursartikelen'
})

const isOpen = ref(false)
const isMouseOver = ref(false)

function handleClick() {
    emit('handleClick')
    isOpen.value = false
}

function mouseOver() {
    isOpen.value = true
    isMouseOver.value = true
}

function mouseLeave() {
    isMouseOver.value = false

    // Child always closes
    if (props.depth !== 0) {
        isOpen.value = false
        return
    }

    // Timer for main submenu
    setTimeout(() => {
        if (!isMouseOver.value) {
            isOpen.value = false
        }
    }, 500)
}
</script>
