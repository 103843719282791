<template>
    <footer
        class="bottom-0 w-full py-8 transition-colors duration-300 dark:text-gray-demegro-light sm:static sm:mt-auto"
        role="contentinfo"
        aria-label="Site footer"
    >
        <div class="container mx-auto">
            <h2 class="mb-4 text-[21px] font-bold">
                Assortiment
            </h2>

            <div class="grid grid-cols-5 gap-3">
                <ClientOnly>
                    <div v-for="chapter in useChapterStore().chapters" :key="chapter.id">
                        <NuxtLink
                            :to="chapter.slug"
                            class="text-sm transition-colors hover:underline dark:hover:underline"
                            :aria-label="`Navigate to ${chapter.title}`"
                        >
                            {{ chapter.title }}
                        </NuxtLink>
                    </div>
                </ClientOnly>
            </div>
        </div>

        <div class="container mx-auto my-8">
            <hr class="border-t border-white dark:border-gray-demegro-light">
        </div>

        <div class="container mx-auto mt-8">
            <div class="grid grid-cols-5 gap-3 text-sm">
                <div class="col-span-2" />
                <div>
                    <p class="mb-4 text-[21px] font-black">
                        Vragen?
                    </p>
                    <div class="space-y-2 transition-colors">
                        <NuxtLink to="/contact" class="block hover:underline dark:hover:underline">
                            Contact
                        </NuxtLink>
                        <NuxtLink to="/veelgestelde-vragen" class="block hover:underline dark:hover:underline">
                            Veelgestelde vragen
                        </NuxtLink>
                        <NuxtLink to="/algemene-voorwaarden" class="block hover:underline dark:hover:underline">
                            Algemene voorwaarden
                        </NuxtLink>
                    </div>
                </div>
                <div>
                    <p class="mb-4 text-[21px] font-black">
                        Over Ons
                    </p>
                    <div class="space-y-2 transition-colors">
                        <NuxtLink to="/over-ons" class="block hover:underline dark:hover:underline">
                            Over ons
                        </NuxtLink>
                        <NuxtLink to="/vacatures" class="block hover:underline dark:hover:underline">
                            Vacatures
                        </NuxtLink>
                    </div>
                </div>
                <div>
                    <p class="mb-4 text-[21px] font-black">
                        Contact
                    </p>
                    <div class="row-span-3">
                        <p>Kovel 3</p>
                        <p>5431 ST Cuijk</p>
                        <p>Nederland</p>
                        <p>info@demegro.nl</p>
                        <p>0485 - 318 570</p>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>
