<template>
    <nav>
        <div class="flex h-16 flex-wrap items-center justify-between">
            <div class="flex items-center">
                <div class="flex items-baseline space-x-20">
                    <NavMenubarSubitem
                        v-for="(item, index) in menuStore.menuItems"
                        :key="index"
                        :item="item"
                        :depth="0"
                        :loading="useChapterStore().loading && item.id === 1"
                    />
                </div>
            </div>
            <div class="h-[46px] w-[317px]">
                <NavSearchbar />
            </div>
        </div>
    </nav>
</template>

<script setup lang="ts">
const menuStore = useMenuStore()
</script>
