<template>
    <div class="relative">
        <template v-if="loading">
            <div class="flex h-64 items-center justify-center">
                <font-awesome-icon
                    :icon="['fas', 'circle-notch']"
                    class="animate-spin text-4xl text-gray-900 dark:text-gray-demegro-light"
                />
            </div>
        </template>
        <template v-else>
            <div class="relative overflow-hidden">
                <div
                    class="flex transition-transform duration-300 ease-in-out"
                    :style="{ transform: `translateX(-${currentSlide * 100}%)` }"
                >
                    <div
                        v-for="(article, index) in articles"
                        :key="article.id"
                        class="w-[23.1%] flex-shrink-0"
                        :class="{
                            'ml-8': index % 4 !== 0 && index !== 0,
                            'ml-1': index % 4 === 0 && index !== 0,
                        }"
                    >
                        <div
                            class="flex h-[320px] flex-col space-y-5 rounded-lg bg-white p-4 transition-colors duration-300 dark:bg-gray-800"
                        >
                            <div class="flex h-3/6 items-center justify-center">
                                <NuxtLink :to="article.slug" class="flex grow items-center justify-center">
                                    <DPicture
                                        :src="article.images?.[0]?.src"
                                        class="h-full w-[60%] object-contain"
                                        loading="lazy"
                                    />
                                    <template
                                        v-if="
                                            article.is_demedent
                                                || article.manufacturer_name?.toUpperCase() === 'DEMEDENT'
                                        "
                                    >
                                        <NuxtImg
                                            src="/no_risk_sticker.webp"
                                            class="absolute right-2 top-2 z-10 size-20 -rotate-[13deg]"
                                        />
                                    </template>
                                </NuxtLink>
                            </div>
                            <div class="flex h-3/6 flex-col justify-between">
                                <NuxtLink :to="article.slug">
                                    <p class="truncate text-left text-xs text-gray-demegro dark:text-zinc-400">
                                        {{ article.manufacturer_name }}
                                    </p>
                                    <h3
                                        class="line-clamp-2 text-left font-bold line-height-sm text-gray-800 dark:text-gray-demegro-light"
                                    >
                                        {{ article.title }}
                                    </h3>
                                </NuxtLink>
                                <div
                                    class="flex shrink-0 justify-between text-lime-demegro dark:text-dark-lime-demegro"
                                >
                                    <span class="text-base">
                                        {{ authenticated ? 'Nu vanaf' : 'Prijs' }}
                                    </span>
                                    <span
                                        :class="{
                                            'text-[22px] font-black': authenticated,
                                            'text-base font-medium italic': !authenticated,
                                        }"
                                    >
                                        {{
                                            authenticated
                                                ? article.price
                                                    ? `€ ${Number(article.price).toFixed(2).replace('.', ',')}`
                                                    : 'Prijs op aanvraag'
                                                : 'Log in voor uw prijs'
                                        }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="currentSlide > 0" class="absolute inset-y-0 left-0 flex items-center">
                <button
                    class="-ml-2 rounded-full bg-white/50 p-2 shadow-md transition-all duration-300 hover:bg-white dark:bg-gray-800/50 dark:hover:bg-dark-gray-demegro"
                    :disabled="currentSlide === 0"
                    @click="prevSlide"
                >
                    <font-awesome-icon
                        :icon="['fas', 'chevron-left']"
                        class="text-gray-demegro dark:text-gray-demegro-light"
                    />
                </button>
            </div>
            <div v-if="currentSlide < maxSlide" class="absolute inset-y-0 right-0 flex items-center">
                <button
                    class="-mr-2 rounded-full bg-white/50 p-2 shadow-md transition-all duration-300 hover:bg-white dark:bg-gray-800/50 dark:hover:bg-dark-gray-demegro"
                    :disabled="currentSlide === maxSlide"
                    @click="nextSlide"
                >
                    <font-awesome-icon
                        :icon="['fas', 'chevron-right']"
                        class="text-gray-demegro dark:text-gray-demegro-light"
                    />
                </button>
            </div>
        </template>
    </div>
</template>

<script setup lang="ts">
import type { Article } from '~/types/demegro'

const authenticated = toRef(useAuthStore(), 'authenticated')

const { data: articles, status } = await useFetch<Article[]>('/api/articles/bannerlist', {
    key: `banner-articles`,
    method: 'GET',
    watch: [authenticated],
    onResponse({ response }) {
        return response._data
    },
    lazy: true,
})

const loading = computed(() => status.value === 'pending')

const currentSlide = ref(0)

const maxSlide = computed(() => {
    if (!articles.value)
        return 4
    return Math.max(0, Math.ceil(articles.value.length / 4) - 1)
})

function nextSlide() {
    if (currentSlide.value < maxSlide.value) {
        currentSlide.value++
    }
}

function prevSlide() {
    if (currentSlide.value > 0) {
        currentSlide.value--
    }
}

function handleResize() {
    currentSlide.value = Math.min(currentSlide.value, maxSlide.value)
}

onMounted(() => {
    window.addEventListener('resize', handleResize)
    handleResize()
})

onBeforeUnmount(() => {
    window.removeEventListener('resize', handleResize)
})
</script>
