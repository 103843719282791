export interface BaseSkeletonObject {
    _skeleton?: boolean
}

export function useSkeletonLoader<T>(count: number) {
    const template = {} as T
    type Skeleton = T & BaseSkeletonObject

    const skeletons = () =>
        Array.from({ length: count })
            .fill(null)
            .map((_, i) => ({
                id: `skeleton-${i}`,
                _skeleton: true,
                ...template,
            }))

    const sequentiallyReplaceItems = (displayedItems: Ref<Skeleton[]>, newItems: Skeleton[], delay = 30) => {
        newItems.forEach((item, index) => {
            setTimeout(() => {
                displayedItems.value[index] = item
            }, index * delay)
        })
    }

    const replaceItems = (displayedItems: Ref<Skeleton[]>, newItems: Skeleton[]) => {
        displayedItems.value = newItems
    }

    const randomlyReplaceItems = (
        displayedItems: Ref<Skeleton[]>,
        newItems: Skeleton[],
        baseDelay = 30,
        increment = 20
    ) => {
        const availableIndices = Array.from({ length: displayedItems.value.length }, (_, i) => i)

        newItems.forEach((item, count) => {
            if (availableIndices.length === 0) return

            const randomIndex = Math.floor(Math.random() * availableIndices.length)
            const indexToReplace = availableIndices.splice(randomIndex, 1)[0]

            setTimeout(
                () => {
                    if (indexToReplace !== undefined) displayedItems.value[indexToReplace] = item
                },
                baseDelay + count * increment
            )
        })
    }

    return {
        skeletons,
        sequentiallyReplaceItems,
        randomlyReplaceItems,
        replaceItems,
    }
}
