<template>
    <NuxtLayout>
        <div class="flex min-h-[70vh] flex-col items-center justify-center px-4">
            <div class="text-center">
                <h1 class="mb-4 text-4xl font-black text-blue-demegro">
                    Oeps! Er is iets fout gegaan
                </h1>
                <h2 class="mb-8 text-2xl font-medium text-gray-900 dark:text-white">
                    {{ error?.statusCode === 404 ? '404 - Deze pagina bestaat niet' : 'Server error' }}
                </h2>
                <p v-if="error?.statusCode !== 404" class="mb-8 text-gray-600 dark:text-gray-400">
                    {{ error?.message || 'Er is een onverwachte fout opgetreden' }}
                </p>
                <div class="flex items-center justify-center gap-4">
                    <button
                        class="rounded-lg bg-blue-demegro px-6 py-2.5 text-white transition-colors duration-300 hover:bg-dark-blue-demegro"
                        @click="handleBack"
                    >
                        <font-awesome-icon :icon="['fas', 'arrow-left']" class="mr-2" />
                        Ga terug
                    </button>
                    <NuxtLink
                        to="/"
                        class="rounded-lg px-6 py-2.5 text-blue-demegro outline outline-1 outline-blue-demegro transition-colors duration-300 hover:bg-blue-50 dark:hover:bg-blue-900"
                    >
                        Naar home
                    </NuxtLink>
                </div>

                <!-- Error Details Collapsible -->
                <div v-if="error?.statusCode !== 404" class="mt-8">
                    <button
                        class="flex items-center justify-center gap-2 text-sm text-gray-500 hover:text-blue-demegro dark:text-gray-400"
                        @click="isErrorDetailsVisible = !isErrorDetailsVisible"
                    >
                        <font-awesome-icon
                            :icon="['fas', isErrorDetailsVisible ? 'chevron-up' : 'chevron-down']"
                            class="transition-transform duration-300"
                        />
                        Voor de ontwikkelaars
                    </button>
                    <div
                        v-if="isErrorDetailsVisible"
                        class="mt-4 w-full rounded-lg bg-gray-50 p-4 text-left"
                    >
                        <pre
                            class="w-full overflow-x-auto whitespace-pre-wrap break-words rounded bg-gray-100 p-4 text-sm text-gray-700 shadow-inner"
                        >
                            {{ JSON.stringify(error, null, 4) }}
                        </pre>
                    </div>
                </div>
            </div>
        </div>
    </NuxtLayout>
</template>

<script setup lang="ts">
const error = useError()
const router = useRouter()
const isErrorDetailsVisible = ref(false)

function handleBack() {
    clearError()
    router.back()
}
</script>
