import type { RefreshHandler } from '@sidebase/nuxt-auth'
import { useAuthStore } from '~/stores/useAuthStore'

class CustomRefreshHandler implements RefreshHandler {
    private refreshInterval?: NodeJS.Timer
    private authStore!: ReturnType<typeof useAuthStore>

    init(): void {
        this.authStore = useAuthStore()

        const visibility = useDocumentVisibility()
        watch(visibility, (newValue) => {
            if (newValue === 'visible' && this.authStore.authenticated)
                this.authStore.handleRefresh()
        })

        this.refreshInterval = setInterval(() => {
            if (this.authStore.authenticated)
                this.authStore.handleRefresh()
        }, 20 * 60 * 1000) // 20 minutes
    }

    destroy(): void {
        if (this.refreshInterval)
            clearInterval(this.refreshInterval)
    }
}

const handler = new CustomRefreshHandler()

export default handler

export const plugin = defineNuxtPlugin(() => {
    return {
        provide: {
            refreshHandler: handler,
        },
    }
})
