<template>
    <div class="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        <div v-for="article in articles" :key="article.id" class="flex">
            <div class="relative h-full w-full rounded-lg bg-white transition-all duration-300 hover:drop-shadow-lg">
                <NuxtLink
                    :to="article.slug"
                    class="flex h-full flex-col"
                    :aria-label="!article._skeleton ? `Navigate to ${article.title} article` : 'Loading article'"
                >
                    <div class="flex h-[276px] items-center justify-center p-5 transition-all duration-300">
                        <DPicture
                            v-if="article.main_image"
                            :src="article.main_image.src"
                            loading="lazy"
                            :alt="article.title"
                            class="w-3/4"
                            :class="{ 'absolute inset-5 animate-pulse rounded-lg bg-gray-100': article._skeleton }"
                        />
                    </div>

                    <!-- Title Container -->
                    <div class="flex flex-grow items-top justify-center px-6 pb-6 text-center">
                        <span
                            class="block text-base font-bold transition-all duration-300"
                            :class="[
                                article._skeleton
                                    ? 'h-6 w-3/4 animate-pulse rounded-md bg-gray-100'
                                    : 'text-black-demegro hover:text-blue-demegro',
                            ]"
                        >
                            {{ article.title }}
                        </span>
                    </div>
                </NuxtLink>
                <NuxtImg
                    v-if="article.is_demedent || article.manufacturer_name?.toUpperCase() === 'DEMEDENT'"
                    src="/no_risk_sticker.webp"
                    class="absolute bottom-16 right-2 z-10 size-20 -rotate-[13deg]"
                />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import type { Article } from '@/types/demegro'

const { skeletons, sequentiallyReplaceItems } = useSkeletonLoader<Article>(12)
const articles = ref(skeletons())

const { data } = await useFetch('/api/articles/demedent/list', {
    default: () => articles.value,
    key: 'articlesDemedentList',
    query: {
        count: 12,
        image: true,
    },
    lazy: true,
    immediate: true,
})

watchEffect(() => {
    if (!data.value?.length)
        return
    sequentiallyReplaceItems(articles, data.value)
})
</script>
