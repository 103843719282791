// WHEN YOU ADD CLASSES, MAKE SURE YOU SAFELIST THEM IN TAILWIND.CONFIG.JS

export const colors = {
    danger: {
        bg: 'bg-red-100',
        text: 'text-red-700',
        border: 'border-red-300',
    },
    warning: {
        bg: 'bg-yellow-100',
        text: 'text-yellow-700',
        border: 'border-yellow-300',
    },
    success: {
        bg: 'bg-lime-100',
        text: 'text-lime-demegro',
        border: 'border-lime-demegro',
    },
    info: {
        bg: 'bg-blue-demegro',
        text: 'text-blue-demegro',
        border: 'border-blue-demegro',
    },
} as const

export type Colors = typeof colors

export const defaultColors: SetColors = {
    bg: 'bg-blue-demegro',
    text: 'text-white',
    border: 'border-blue-demegro',
} as const

export interface SetColors {
    bg?: string
    text?: string
    border?: string
}
