<template>
    <div class="flex flex-col px-8 py-8 gap-6">
        <div class="inline-flex flex-col gap-2">
            <label for="username" class="">E-mailadres om te resetten</label>
            <input
                id="username"
                v-model="email"
                class="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500 transition duration-200"
                type="email"
                required
                @keyup.enter="handleSubmit()"
            >
        </div>

        <div class="flex items-center justify-center gap-4">
            <button
                class="px-4 py-2 text-white rounded-lg shadow-md hover:shadow-lg relative transition-all duration-200"
                :class="[success ? 'bg-green-500 hover:bg-green-600' : 'bg-blue-demegro hover:bg-dark-blue-demegro']"
                @click="handleSubmit()"
            >
                Resetten
                <div v-if="loading" class="flex items-center justify-center">
                    <font-awesome-icon :icon="['fas', 'spinner-third']" class="fa-spin text-2xl" />
                </div>
                <div v-if="success" class="flex items-center justify-center">
                    <font-awesome-icon :icon="['fas', 'check']" class="text-2xl" />
                </div>
            </button>
        </div>

        <TransitionGroup name="fade">
            <div v-if="errorMessage" key="error" class="mt-4 p-3 rounded-md" :class="[errorMessage.colors?.bg, errorMessage.colors?.border, errorMessage.colors?.border ? 'border' : '']">
                <p class="text-sm" :class="errorMessage.colors?.text">
                    {{ errorMessage.message }}
                </p>
            </div>
        </TransitionGroup>
    </div>
</template>

<script setup lang="ts">
const emit = defineEmits(['modalClose'])
const email = ref('')
const loading = ref(false)
const error = ref<null | { status: number, message: string }>(null)
const success = ref(false)

const { getErrorFallback } = useErrorMessages()

const errorMessage = computed(() => {
    if (!error.value)
        return null

    switch (error.value.status) {
        case 400:
            switch (error.value.message) {
                case 'email_required':
                    return getErrorFallback('email_required')
                case 'email_unknown':
                    return getErrorFallback('email_unknown')
                case 'template_not_found':
                    return getErrorFallback('template_not_found')
                case 'email_failed':
                    return getErrorFallback('email_failed')
                case 'password_reset_failed':
                    return getErrorFallback('password_reset_failed')
                default:
                    return getErrorFallback('error')
            }
        default:
            return getErrorFallback('error')
    }
})

async function handleSubmit() {
    if (!email.value?.trim()) {
        return
    }

    loading.value = true
    error.value = null

    try {
        await $fetch('/api/accounts/reset-password', {
            method: 'POST',
            body: { email: email.value },
        })

        success.value = true
        setTimeout(() => {
            emit('modalClose')
        }, 2000)
    }
    catch (err: any) {
        error.value = {
            status: err.statusCode,
            message: err.statusMessage,
        }
    }
    finally {
        loading.value = false
    }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
